import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import { useThemeShade } from '~/theme';

type Props = {
    backgroundColor?: string;
};

/**
 * This hook extends theme shades with a background contrast color.
 * These contrast are based off selectable colors from the CMS.
 * These are usable on all themes, even though some colors belongs to a specific theme, this hook helps resolving the contrast color.
 *
 * Some specific modules use these on top of a background color, for these this hook can be used instead of useThemeShade.
 *
 * @returns @ReturnType
 */
export const useContrastThemeShade = ({ backgroundColor }: Props) => {
    const { colors } = useTheme();
    const theme = useThemeShade({ backgroundColor });

    const backgroundContrastColor = useMemo(() => {
        const {
            CMSThemeBlack,
            CMSThemeDarkGrey,
            CMSThemeDarkerGrey,
            CMSThemeWhite,
            CMSThemeLightGrey,
        } = colors;

        const uppercasedBackgroundColor = backgroundColor?.toUpperCase();

        if (uppercasedBackgroundColor === CMSThemeLightGrey) {
            return CMSThemeWhite;
        }

        if (uppercasedBackgroundColor === CMSThemeBlack) {
            return CMSThemeDarkGrey;
        }

        if (uppercasedBackgroundColor === CMSThemeWhite) {
            return CMSThemeLightGrey;
        }

        if (uppercasedBackgroundColor === CMSThemeDarkerGrey) {
            return CMSThemeDarkGrey;
        }
        // Default to white
        return CMSThemeWhite;
    }, [backgroundColor, colors]);

    return {
        backgroundContrastColor,
        ...theme,
    };
};
