import React, { ComponentProps, ForwardedRef, forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { LinkProps as NextLinkProps } from 'next/link';
import { StyledDiv, StyledLink, StyledLinkPlain, StyledRawLink } from './styled';
import { StrokeText, StrokeTextProps, TextShade, TextVariants } from '~/shared/components';
import { ButtonProps } from '~/shared/components/Button';
import { RouterLink } from '~/shared/components/RouterLink';

export type LinkProps = Partial<Pick<StrokeTextProps, 'strokeHeight' | 'strokeMode' | 'text'>> & {
    textVariant?: TextVariants;
    textShade?: TextShade;
    renderEmptyLinksAsDiv?: boolean;
    type?: 'plain' | 'router' | 'raw';
} & ComponentProps<typeof StyledLink> &
    NextLinkProps &
    Partial<ButtonProps>;

export const Link = forwardRef(
    (
        {
            children,
            strokeMode,
            strokeHeight,
            text,
            textVariant,
            textShade,
            href = '',
            as,
            type,
            target,
            className,
            showHoverIndicator = true,
            title,
            onClick,
            renderEmptyLinksAsDiv,
            ...restProps
        }: LinkProps,
        ref?: ForwardedRef<HTMLAnchorElement>
    ) => {
        if (!href && renderEmptyLinksAsDiv) {
            return (
                <StyledDiv as={as} className={className}>
                    {children}
                </StyledDiv>
            );
        }

        if (!href) {
            console.warn('Warning, link component rendered without a href! className:', className);
        }
        const BaseLink = (
            <StyledLink
                ref={ref}
                as={as}
                className={className}
                href={href}
                target={target}
                title={title}
                onClick={onClick}
            >
                {children && (
                    <>
                        {children}
                        {showHoverIndicator && (
                            <StrokeText {...{ strokeMode, strokeHeight }} shade={textShade} />
                        )}
                    </>
                )}
                {text && (
                    <StrokeText
                        {...(showHoverIndicator ? { strokeMode, strokeHeight } : {})}
                        text={text}
                        shade={textShade}
                        variant={textVariant}
                    />
                )}
            </StyledLink>
        );

        if (type === 'router') {
            return (
                <RouterLink href={href} {...restProps}>
                    <StyledRawLink
                        className={className}
                        target={target}
                        title={title}
                        as={as}
                        aria-label={restProps['aria-label']}
                        onClick={onClick}
                    >
                        {children}
                    </StyledRawLink>
                </RouterLink>
            );
        }

        if (type === 'plain') {
            return (
                <RouterLink href={href} {...restProps}>
                    <StyledLinkPlain
                        ref={ref}
                        as={as}
                        children={children}
                        className={className}
                        target={target}
                        title={title}
                        onClick={onClick}
                    />
                </RouterLink>
            );
        }

        if (type === 'raw') {
            return BaseLink;
        }

        return (
            <RouterLink href={href} {...restProps}>
                {BaseLink}
            </RouterLink>
        );
    }
);
