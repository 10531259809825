import styled from '@emotion/styled';

export const StyledDiv = styled.div(() => ({}));

export const StyledLink = styled.a(({ theme }) => ({
    position: 'relative',
    color: 'currentcolor',
    fontSize: theme.text.variants.body,
    lineHeight: theme.lineHeights.base,
    letterSpacing: 0,
    textDecoration: 'none',
}));

export const StyledLinkPlain = styled.a({
    color: 'currentcolor',
    ':is(:hover, :focus)': {
        textDecoration: 'underline',
    },
});

export const StyledRawLink = styled.a({
    textDecoration: 'none',
});
